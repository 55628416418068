// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

@import "../node_modules/ng-pick-datetime/assets/style/picker.min.css";


.owl-dt-container {
  font-size: 15px;
}
// .e-daterangepicker.e-popup {
//   max-height: 300px;
//   overflow: auto;
// }
.page-layout.carded.fullwidth > .center.company-info-max-h {
  max-height: calc(100vh - 100px);
}
.e-range-header {
  display: none;
}
.e-presets .e-lib .e-keyboard {
  height: min-content !important;
}
a {
  font-family: muli-semibold;
  text-decoration: none !important;
}
.muli-bold {
  font-family: muli-bold;
}
body.theme-default {
.mat-select-panel {
  .mat-optgroup-label {
    height: 36px;
    font-family: muli-bold;
    font-size: 14px;
    line-height: 36px;
  }
  .mat-option {
    height: 38px !important;
  }
}
}
.overflow-auto {
  overflow: auto;
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  mat-spinner {
    z-index: 10000;
  }
}
.mat-nav-list {
  padding-top: 0 !important;
  .mat-list-item {
    height: 36px !important;
    font-size: 13px !important;
  }
}
.mat-paginator-range-label {
  margin: 0 !important;
}
.mat-toolbar-row {
  &.secondary-header {
    // box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    // background-color: #27ae60;
    // padding: 0 24px;
    margin-top: 8px;
    // height: 40px;
    // min-height: 40px;
    // max-height: 40px;
    height: min-content;
  }
  &.secondary-headers {
    height: min-content;
    border-bottom: 1px solid #cfcfcf;
    background: #fff;
  }
}
.nav {
  list-style: none;
  li {
    display: inline-block;
    // line-height: 36px;
    text-align: center;
    a {
      // display: block;
      color: #000;
      text-decoration: none !important;
      font-size: 13px;
      height: 42px;
      padding: 0 12px;
      cursor: pointer;
      box-sizing: border-box;
      min-width: 120px;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      position: relative;
      color: rgba(0, 0, 0, 0.87);
      transition: 0.3s linear;
      border-bottom: 2px solid transparent;
      &.active {
        color: rgba(0, 0, 0, 1);
        transition: 0.3s linear;
        border-bottom: 2px solid #27ae60;
      }
    }
  }
}
.border-b-1 {
  border-bottom: 1px solid #cfcfcf;
}
.top-nav {
  list-style: none;
  li {
    display: inline-block;
    // line-height: 36px;
    text-align: center;
    a {
      // display: block;
      color: #000;
      text-decoration: none !important;
      font-size: 13px;
      height: 42px;
      padding: 0 12px;
      cursor: pointer;
      box-sizing: border-box;
      min-width: 120px;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      position: relative;
      color: rgba(0, 0, 0, 0.87);
      transition: 0.3s linear;
      border-bottom: 2px solid transparent;
      &.active {
        color: rgba(0, 0, 0, 1);
        transition: 0.3s linear;
        border-bottom: 2px solid #27ae60;
      }
    }
  }
}
.content-card {
  // background: transparent !important;
  border-radius: 4px !important;
}
.border-1 {
  border: 1px solid #cfcfcf;
}
.mat-table {
  // border: 1px solid #cfcfcf;

  .mat-header-row {
    min-height: 40px;
    background: #e9edef;
    border: 1px solid #cfcfcf;

    &.mat-table-sticky {
      background: #e9edef;
    }

    .mat-header-cell {
      font-weight: bold;
      color: #000000;
      letter-spacing: 0.5px;
      font-size: 12px;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
  }

  .mat-row {
    min-height: 38px;
    border: 1px solid #cfcfcf;
    border-top: none;

    .mat-cell {
      font-size: 13px;
      letter-spacing: 0.5px;
      display: flex;
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      text-transform: capitalize;
      &.lowercase {
        text-transform: inherit;
      }

      .mat-select-value-text {
        font-size: 13px;
      }

      .mat-radio-label-content {
        font-size: 13px;
      }

      .mat-slide-toggle {
        .mat-slide-toggle-ripple {
          display: none;
        }
      }
    }
  }
  &.display-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border: none;

    > .mat-header-row {
      min-height: 30px;
      display: table-row;

      > .mat-header-cell {
        display: table-cell;
        vertical-align: middle;
      }
    }

    > .mat-row {
      min-height: 30px;
      display: table-row;

      > .mat-cell {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  &.no-header-bg {
    .mat-header-row {
      background: none;

      &.mat-table-sticky {
        background: none;
      }
    }
  }
}
.sub-heading {
  font-size: 13px;
  font-family: muli-bold;
}
.heading {
  font-size: 14px;
  font-family: muli-bold;
}

.search-wrapper {
  position: relative;

  .search1 {
    background: #f7f7f7;
    border-radius: 25px;
    padding: 8px 8px 8px 40px;
    width: 100%;
    border: 1px solid #cfcfcf;
  }

  .search_icon {
    position: absolute;
    top: 6px;
    color: #c5c5c5;
    left: 8px;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
  }
}
.mat-paginator {
  background: transparent !important;
}

.page-layout {
  &.inner-sidebar {
    &.right-sidebar {
      >fuse-sidebar {
        background: #fff;
        border-radius: 4px !important;
        border: $border-color solid 1px;

      }
    }
  }
}
// mat-form-field

.mat-form-field {
  .mat-form-field-wrapper {
    .mat-form-field-infix {
      border: none;

      .mat-input-element {
        font-size: 12px;
      }

      .mat-chip-list-wrapper {
        overflow-y: auto;
        min-height: 30px;
        // height: 32px;
        max-height: 70px;

        .mat-standard-chip {
          margin: 2px;
          min-height: 20px;
          font-size: 11px;
        }
      }
    }

    .mat-select {
      .mat-select-value-text {
        font-size: 12px;
      }
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-flex {
        .mat-form-field-outline-thick {
          .mat-form-field-outline-end,
          .mat-form-field-outline-gap,
          .mat-form-field-outline-start {
            border-width: 1px;
          }
        }

        .mat-form-field-infix {
          padding: 12px 0px;
          border: none;

          .mat-input-element {
            font-size: 12px;
          }

          .mat-form-field-label-wrapper {
            .mat-form-field-label {
              font-size: 12px;
              font-family: $font-regular;
              text-transform: capitalize;
              line-height: 14px;
            }
          }
        }

        .mat-form-field-suffix {
          .mat-datepicker-toggle {
            .mat-icon-button {
              height: 1.5em;
            }
          }
        }
      }

      .mat-error {
        padding: 7px 4px 0;
        font-size: 10px;
        background: #fff;
        display: inline-block;
      }
    }

    .mat-select {
      .mat-select-value-text {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

.mat-checkbox {
  &.mat-checkbox-disabled {
    cursor: no-drop;
  }
}
.mat-input-element {
  &:disabled {
    cursor: no-drop;
  }
}
// footer css
.footer {
  position: fixed;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 12px 12px 0px 0px;
  font-family: muli-bold;
  color: #fff;
  display: flex;
  z-index: 999;
}

// modal scss
.mat-dialog-container {
  padding: 0 !important;
  overflow: inherit !important;
  position: relative;
  border-radius: 20px !important;
  min-height: initial !important;

  .mat-dialog-title {
    padding: 5px 24px;
    margin: 0;
    background-color: #e9edef;
    color: #000;
    font-size: 14px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-family: muli-bold;
  }

  .mat-dialog-title + .close-icon {
    width: 26px;
    height: 26px;
    position: absolute;
    right: -10px;
    top: -14px;

    .mat-button-wrapper {
      i {
        font-size: 16px;
        color: #fff;
        line-height: 8px;
      }
    }
  }

  .mat-dialog-content {
    margin: 0;
    padding: 12px 24px 12px 24px;
    max-height: 70vh;
  }

  .mat-dialog-actions {
    margin: 0;
    padding: 0px 24px;
    min-height: 42px;
  }
}

.mat-form-field {
  &.country-p{
  .mat-form-field-wrapper {
    .mat-form-field-infix {
      padding: 9px 0px !important;
    }
  }
}
}
// ckeditor styles

ckeditor{
  &.ng-invalid.ng-touched {
    >.cke {
      border:  #f44336 solid 1px;
    }
  }
}

.scroll-content-height {
  height: calc(100vh - 280px);
}

// media screens

@media screen and (max-width: 1199px) {
  .mat-form-field {
    &.mat-form-field-appearance-outline {
      .mat-form-field-infix {
        .mat-input-element {
          line-height: 16px;
        }

        .mat-form-field-label-wrapper {
          top: -4px;

          .mat-form-field-label {
            font-size: 12px;
            top: 24px;
            line-height: 18px;
            letter-spacing: 0.3px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .mat-form-field {
    &.mat-form-field-appearance-outline {
      .mat-form-field-infix {
        .mat-input-element {
          line-height: 16px;
        }

        .mat-form-field-label-wrapper {
          top: -4px;

          .mat-form-field-label {
            font-size: 12px;
            top: 24px;
            line-height: 18px;
            letter-spacing: 0.3px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1401px) and (max-width: 1900px) {
  .mat-form-field {
    &.mat-form-field-appearance-outline {
      .mat-form-field-infix {
        .mat-input-element {
          line-height: 16px;
        }

        .mat-form-field-label-wrapper {
          top: -4px;

          .mat-form-field-label {
            font-size: 14px;
            top: 24px;
            line-height: 18px;
            letter-spacing: 0.3px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1901px) {
  .mat-form-field {
    &.mat-form-field-appearance-outline {
      .mat-form-field-infix {
        .mat-input-element {
          line-height: 16px;
        }

        .mat-form-field-label-wrapper {
          top: -4px;

          .mat-form-field-label {
            font-size: 12px;
            top: 24px;
            line-height: 18px;
            letter-spacing: 0.3px;
          }
        }
      }
    }
  }
}

.white-space-wrap{
  label {
    white-space: pre-wrap !important;
  }
}


.mat-typography {
   &.mat-dialog-content {
        height:auto;
   } 
  .mat-grid-tile-content
  {
    display:block !important;
  }  
}


button{
  &.teal-fg.mat-badge-medium{
      &.mat-badge-overlap{
          &.mat-badge-after {
              .mat-badge-content{
                  // width:18px;
                  // height: 18px;
                  // line-height: 18px;
                  // font-size: 9px;
                  // right: 9px !important;
                  right: 22px;
                  font-size: 11px;
                  height: 18px;
                  width: 18px;
                  line-height: 18px;
                  padding: 0px;
                  margin-top: 10px;
              }
          }
      
  }
  }
}

